import { __awaiter, __generator } from "tslib";
import { defineComponent, ref } from "vue";
import { Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import BaseField from "@/components/BaseField.vue";
import { useRouter } from "vue-router";
export default defineComponent({
    name: "password-reset",
    components: {
        Form: Form,
        BaseField: BaseField,
    },
    setup: function () {
        var _this = this;
        var store = useStore();
        var router = useRouter();
        var showForm = ref({
            forgotPassword: true,
            verifyCode: false,
            resetPassword: false,
        });
        var currentEmail = ref("");
        var currentCode = ref("");
        var submitForgetPasswordButton = ref(null);
        var submitVerifyCodeButton = ref(null);
        var submitResetPasswordButton = ref(null);
        //Create form validation object
        var forgotPassword = Yup.object().shape({
            email: Yup.string().email("Invalid").required("Required").label("Email"),
        });
        //Create form validation object
        var verifyCode = Yup.object().shape({
            code: Yup.string().required("Required").label("Code"),
        });
        var resetPassword = Yup.object().shape({
            password: Yup.string().min(6).required("Required").label("Password"),
            password_confirmation: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
        });
        //Form submit function
        var onSubmitForgotPassword = function (values) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        // eslint-disable-next-line
                        // Activate loading indicator
                        (_a = submitForgetPasswordButton.value) === null || _a === void 0 ? void 0 : _a.setAttribute("data-kt-indicator", "on");
                        // dummy delay
                        // Send login request
                        return [4 /*yield*/, store
                                .dispatch(Actions.FORGOT_PASSWORD, values)
                                .then(function () {
                                // currentEmail.value = values.email;
                                showForm.value.forgotPassword = false;
                                showForm.value.verifyCode = true;
                            })
                                .catch(function () {
                                var _a;
                                (_a = submitForgetPasswordButton.value) === null || _a === void 0 ? void 0 : _a.removeAttribute("data-kt-indicator");
                            })];
                    case 1:
                        // dummy delay
                        // Send login request
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var onSubmitVerifyCode = function (values) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        // eslint-disable-next-line
                        // Activate loading indicator
                        (_a = submitVerifyCodeButton.value) === null || _a === void 0 ? void 0 : _a.setAttribute("data-kt-indicator", "on");
                        // dummy delay
                        // Send login request
                        return [4 /*yield*/, store
                                .dispatch(Actions.VERIFY_CODE, {
                                email: currentEmail.value,
                                code: values.code,
                            })
                                .then(function () {
                                showForm.value.verifyCode = false;
                                showForm.value.resetPassword = true;
                            })
                                .catch()];
                    case 1:
                        // dummy delay
                        // Send login request
                        _b.sent();
                        setTimeout(function () {
                            var _a;
                            (_a = submitVerifyCodeButton.value) === null || _a === void 0 ? void 0 : _a.removeAttribute("data-kt-indicator");
                            // eslint-disable-next-line
                        }, 250);
                        return [2 /*return*/];
                }
            });
        }); };
        var onSubmitResetPassword = function (values) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        // eslint-disable-next-line
                        // Activate loading indicator
                        (_a = submitVerifyCodeButton.value) === null || _a === void 0 ? void 0 : _a.setAttribute("data-kt-indicator", "on");
                        // dummy delay
                        // Send login request
                        return [4 /*yield*/, store
                                .dispatch(Actions.RESET_PASSWORD, {
                                email: currentEmail.value,
                                code: currentCode.value,
                                password: values.password,
                                password_confirmation: values.password_confirmation,
                            })
                                .then(function () {
                                router.push({ name: "login" });
                            })];
                    case 1:
                        // dummy delay
                        // Send login request
                        _b.sent();
                        setTimeout(function () {
                            var _a;
                            (_a = submitVerifyCodeButton.value) === null || _a === void 0 ? void 0 : _a.removeAttribute("data-kt-indicator");
                            // eslint-disable-next-line
                        }, 250);
                        return [2 /*return*/];
                }
            });
        }); };
        return {
            onSubmitForgotPassword: onSubmitForgotPassword,
            forgotPassword: forgotPassword,
            submitForgetPasswordButton: submitForgetPasswordButton,
            showForm: showForm,
            verifyCode: verifyCode,
            currentEmail: currentEmail,
            onSubmitVerifyCode: onSubmitVerifyCode,
            currentCode: currentCode,
            submitResetPasswordButton: submitResetPasswordButton,
            onSubmitResetPassword: onSubmitResetPassword,
            resetPassword: resetPassword,
        };
    },
});
